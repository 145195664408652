<template>
  <div class="page">
    <v-container>
      <h1 class="title">Наши тарифы</h1>
      <div class="tariffs">
        <div class="tariffs__left">
          <div class="tariffs__row">
            <div class="tariffs__name">Преподаватель:</div>
            <div class="tariffs__type">
              <div
                  class="type"
                  :class="{ active: type.id === tariff.type.id }"
                  v-for="type in tariffs.type"
                  :key="type.id"
                  @click="typeChange(type)"
              >
                {{ type.name }}
              </div>
            </div>
          </div>
          <div class="tariffs__row">
            <div class="tariffs__name">Длительность урока:</div>
            <div class="tariffs__type">
              <div
                  class="type"
                  :class="{ active: type.id === tariff.duration.id }"
                  v-for="type in tariffs.duration"
                  :key="type.id"
                  @click="durationChange(type)"
              >
                {{ type.time }} минут
              </div>
            </div>
          </div>
          <div class="tariffs__row">
            <div class="tariffs__name">Количество занятий:</div>
            <div class="tariffs__type">
              <div
                  class="type"
                  :class="{ active: type.id === tariff.count.id }"
                  v-for="type in tariffs.count"
                  :key="type.id"
                  @click="lessonsChange(type)"
              >
                {{ type.count }}
              </div>
            </div>
          </div>
        </div>
        <div class="tariffs__right">
          <div class="tariffs__bg">
            <v-img src="@/assets/images/flower.png"/>
          </div>
          <div class="tariff">
            <div class="tariff__price">
              {{ selectedTariff.price?.toLocaleString("ru") }} ₽
            </div>
            <div class="tariff__plan">
              <span>● {{ tariffType.name }}</span>
              <span>● {{ tariffDuration.time }} минут</span>
              <span>● {{ tariffCount.count }} занятий</span>
            </div>
            <v-btn
                @click="openPay"
                fab
                color="secondary"
                class="tariff__btn btn-circle"
            >Оплатить
            </v-btn
            >
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import axios from "axios";

export default {
  name: "Tariffs",
  data() {
    return {
      tariff: {
        type: {
          id: 1,
          name: "Стандарт",
          fetch: "standard",
        },
        duration: {
          id: 1,
          time: 25,
        },
        count: {
          id: 1,
          count: 8,
        },
      },
      tariffs: {
        type: [
          {
            id: 1,
            name: "Стандарт",
            fetch: "standard",
          },
          {
            id: 2,
            name: "Премиум",
            fetch: "premium",
          },
          {
            id: 3,
            name: "SpeakingClub",
            fetch: "native",
          },
        ],
        duration: [
          {
            id: 1,
            time: 25,
          },
          {
            id: 2,
            time: 55,
          },
        ],
        count: [
          {
            id: 1,
            count: 8,
          },
          {
            id: 2,
            count: 12,
          },
          {
            id: 3,
            count: 16,
          },
          {
            id: 4,
            count: 20,
          },
          {
            id: 5,
            count: 96,
          },
        ],
      },
      selectedTariff: {},
    };
  },
  methods: {
    async openPay() {
      if (!this.isAuth) {
        this.showAlert({
          show: true,
          type: "info",
          msg: "Авторизуйтесь для оплаты",
        });
        this.setShowLoginPopup({boolean: true});

        return;
      }
      this.setIsLoading(true);
      try {
        const response = await axios({
          baseURL: `${this.URL}/api/u/payments`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.token}`,
          },
          method: "POST",
          data: JSON.stringify({
            tariff: `/api/tariffs/${this.selectedTariff.id}`,
            promocode: "",
          }),
        });
        if (response.data.status === "ok") {
          const redirect = response.data.redirect;
          window.open(redirect, "_self");
        } else {
          this.showAlert({
            show: true,
            type: "success",
            msg: response.data.message,
          });
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.setIsLoading(false);
      }
    },
    async getTariff() {
      this.setIsLoading(true);
      try {
        const response = await fetch(
            `${this.URL}/api/tariffs?type=${this.tariff.type.fetch}&duration=${this.tariff.duration.time}&lessons=${this.tariff.count.count}`
        );
        const data = await response.json();
        this.selectedTariff = data[0];
      } catch (e) {
        console.log(e);
      } finally {
        this.setIsLoading(false);
      }
    },
    typeChange(type) {
      if (type.id === 1) {
        this.tariff.type = {
          id: type.id,
          name: "Стандарт",
          fetch: "standard",
        };
      } else if (type.id === 2) {
        this.tariff.type = {
          id: type.id,
          name: "Премиум",
          fetch: "premium",
        };
      } else if (type.id === 3) {
        this.tariff.type = {
          id: type.id,
          name: "SpeakingClub",
          fetch: "native",
        };
      }
      this.getTariff();
    },
    durationChange(type) {
      if (type.id === 1) {
        this.tariff.duration = {
          id: type.id,
          time: 25,
        };
      } else if (type.id === 2) {
        this.tariff.duration = {
          id: type.id,
          time: 55,
        };
      }
      this.getTariff();
    },
    lessonsChange(type) {
      if (type.id === 1) {
        this.tariff.count = {
          id: type.id,
          count: 8,
        };
      } else if (type.id === 2) {
        this.tariff.count = {
          id: type.id,
          count: 12,
        };
      } else if (type.id === 3) {
        this.tariff.count = {
          id: type.id,
          count: 16,
        };
      } else if (type.id === 4) {
        this.tariff.count = {
          id: type.id,
          count: 20,
        };
      } else if (type.id === 5) {
        this.tariff.count = {
          id: type.id,
          count: 96,
        };
      }
      this.getTariff();
    },
    ...mapMutations(["showAlert"]),
    ...mapActions(["setIsLoading", "setShowLoginPopup"]),
  },
  computed: {
    tariffType() {
      return this.tariffs.type.find((type) => type.id === this.tariff.type.id);
    },
    tariffDuration() {
      return this.tariffs.duration.find(
          (duration) => duration.id === this.tariff.duration.id
      );
    },
    tariffCount() {
      return this.tariffs.count.find(
          (count) => count.id === this.tariff.count.id
      );
    },
    ...mapGetters(["URL", "isAuth", "token"]),
  },
  beforeMount() {
    this.getTariff();
  },
};
</script>

<style lang="scss" scoped>
.tariffs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 190px;

  &__left {
    flex: 0 1 450px;
    display: flex;
    flex-direction: column;
    gap: 45px;
  }

  &__row {
  }

  &__name {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    text-transform: uppercase;
    margin-bottom: 12px;
  }

  &__type {
    display: flex;
    gap: 10px;
  }

  .type {
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #787878;
    border: 1px solid;
    border-radius: 200px;
    height: 45px;
    flex: 1 1 auto;
    cursor: pointer;
    transition: all 0.3s;

    &.active {
      background: #e6a6f6;
      color: #fff;
      border-color: #e6a6f6;
    }
  }

  &__right {
    flex: 0 1 750px;
    position: relative;
  }

  &__bg {
    width: 248px;
    height: 248px;
    position: absolute;
    top: -110px;
    right: -110px;
  }
}

.tariff {
  background: #fff;
  box-shadow: 0 4px 40px rgba(48, 48, 48, 0.3);
  border-radius: 20px;
  position: relative;
  z-index: 2;
  padding: 70px 100px 180px;

  &__title {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 40px;
  }

  &__plan {
    display: flex;
    justify-content: space-between;
    gap: 5px;

    span {
      font-size: 20px;
      color: #727272;
      flex: 0 0 30%;
    }
  }

  &__price {
    font-family: "Nunito";
    font-weight: 900;
    font-size: 60px;
    text-transform: uppercase;
    color: #b87dea;
    text-align: center;
    margin-bottom: 40px;
  }

  &__btn {
    position: absolute;
    left: 50%;
    bottom: -100px;
    transform: translateX(-50%);
  }
}

@media (max-width: 1560px) {
  .tariffs {
    &__right {
      flex: 0 1 650px;
    }
  }
  .tariff {
    padding: 70px 80px 140px;

    &__btn {
      bottom: -70px;
    }
  }
}

@media (max-width: 1200px) {
  .tariffs {
    flex-direction: column;
    gap: 40px;

    &__right,
    &__left {
      flex: 1 1 100%;
      width: 100%;
    }

    &__name {
      font-size: 24px;
    }

    &__bg {
      top: auto;
      bottom: -110px;
    }
  }
  .tariff {
    &__price {
      font-size: 52px;
      margin-bottom: 32px;
    }

    &__title {
      font-size: 24px;
      margin-bottom: 32px;
    }

    &__plan {
      text-align: center;
    }
  }
}

@media (max-width: 768px) {
  .tariffs {
    &__name {
      font-size: 18px;
    }

    &__type {
      flex-wrap: wrap;
    }

    .type {
      flex: 0 1 auto;
    }

    &__bg {
      display: none;
    }
  }
  .tariff {
    padding: 60px 14px 85px;
    margin: 0 calc(50% - 50vw);
    border-radius: 0;

    &__price {
      font-size: 48px;
    }

    &__plan {
      text-align: center;

      span {
        font-size: 13px;
      }
    }
  }
  .type {
    padding: 0 30px;
  }
}
</style>
