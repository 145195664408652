<template>
  <div class="page">
    <v-container>
      <v-row v-if="student" class="mt-10">
        <v-col
          v-for="(balance,i) in student.balances"
          :key="i"
          cols="12"
          xl="6"
        >
          <v-card class="card">
            <v-card-title class="card__title">
              Тариф
              «{{ balance.type === 'standard' ? 'Стандарт' : balance.type === 'premium' ? 'Премиум' : 'SpeakingClub' }}»,
							<br>
              {{ balance.duration }} минут
            </v-card-title>
            <v-progress-linear
              :value="completedLessons(balance)"
              background-color="#4C4C4C"
              height="10"
              class="rounded-xl"
            ></v-progress-linear>
            <v-card-text class="totals">
              <div class="total">Забронировано: <span>{{ balance.bookedLessons }} занятий</span></div>
              <v-spacer/>
              <div class="total">Осталось: <span>{{ balance.availableLessons }} занятий</span></div>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn @click="$router.push({name:'tariffs'})" class="px-5" style="font-size: 14px !important;"
                     outlined rounded large color="primary">Пополнить баланс
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="false" class="mt-10">
        <v-col cols="12">
          <h2 class=" title title-md">История операций</h2>
          <v-card width="98%" class="mx-auto">
            <v-data-table
              locale="ru-RU"
              :headers="headersHistory"
              :items="history"
              disable-sort
            >
              <template v-slot:item.btn="{item}">
                <a :href="item.btn" style="text-decoration: underline" target="_blank">Подробнее</a>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog width="80%" v-model="showPaid" transition="dialog-bottom-transition">
      <div v-if="successPaid" class="success">
        <v-card>
          <v-card-actions>
            <v-spacer/>
            <v-btn
              icon
              @click="showPaid = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-actions>
          <div class="popup__body">
            <h3>Спасибо! <br> Оплата прошла успешно</h3>
            <div class="text">В ближайшее время администратор подтвердит оплату и уроки станут доступными</div>
          </div>
        </v-card>
      </div>
      <div v-else class="false">
        <v-card>
          <v-card-actions>
            <v-spacer/>
            <v-btn
              icon
              @click="showPaid = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-actions>
          <div class="popup__body">
            <h3>К сожалению <br> во время оплаты произошел сбой.</h3>
            <div class="text">Попробуйте еще раз <br> или обратитесь к системному администратору.</div>
          </div>
        </v-card>
      </div>
    </v-dialog>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import {format, getTime} from "date-fns";

export default {
  name: "ProfilePage",
  data() {
    return {
      search: '',
      headers: [
        {text: 'Дата', value: 'date'},
        {text: 'Время', value: 'time'},
        {text: 'Преподаватель', value: 'teacher'},
        {text: 'Ссылка', value: 'btn', sortable: false,}
      ],
      lessons: [],
      headersHistory: [
        {text: 'Дата', value: 'date'},
        {text: 'Вид операции', value: 'type'},
        {text: '', value: 'btn', sortable: false,}
      ],
      history: [
        {
          date: format(new Date(), 'd.M.YYY'),
          type: 'Забронирован урок (“Премиум”, 55 мин.)',
          btn: 'https://vk.com'
        },

      ],
      showPaid: null,
      successPaid: null,
      paidInfo: {
        status: null,
        paid: null,
      }
    }
  },
  methods: {
    completedLessons(balance) {
      return 100 - (balance.availableLessons / (balance.bookedLessons + balance.availableLessons) * 100)
    },
    async redirectFromYookassa() {
      this.setIsLoading(true)
      try {
        if (this.idempotenceKey) {
          const response = await axios(`${this.URL}/api/u/payments/${this.idempotenceKey}`, {
            headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${this.token}`
            }
          });
          const isPaid = response.data.paid;
          this.showPaid = true;
          if (isPaid) {
            this.successPaid = true;
          } else {
            this.successPaid = false;
          }
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.setIsLoading(false)
      }

    },
    ...mapActions(['setIsLoading']),
  },

  computed: {
    ...mapGetters(['profileTab', 'student', 'token', 'URL', 'idempotenceKey']),
    sortingLessons() {
      return this.lessons.sort((a, b) => getTime(new Date(b.dateTimeAt)) - getTime(new Date(a.dateTimeAt)))
    }
  },
  mounted() {
    this.redirectFromYookassa()
  },

}
</script>

<style lang="scss" scoped>
.page {
  padding-bottom: 90px;
}

.card {
  padding: 40px !important;
  border-radius: 15px !important;

  &__title {
    font-family: 'Nunito', 'sans-serif';
    font-weight: 900;
    font-size: 18px;
    line-height: 140%;
    text-transform: uppercase;
    color: #414141;
    text-align: center;
    justify-content: center;
    margin-bottom: 28px;
  }

  .totals {
    display: flex;
  }

  .total {
    font-weight: 400;
    font-size: 14px;
    color: #9C9C9C;
    display: flex;
    align-items: center;
    gap: 6px;

    span {
      color: #4C4C4C;
    }
  }
}

.table-link {
  display: flex;
  font-size: 14px;
  color: #fff;
  background: #B87DEA;
  border-radius: 40px;
  height: 32px;
  align-items: center;
  justify-content: center;
  padding: 0 5px;

  &.disabled {
    background: gray;
    pointer-events: none;
    cursor: inherit;
  }
}

.counter {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.count {
  font-size: 24px;

  span {
    font-size: 28px;
    font-weight: 700;
  }
}

.btns {
  display: flex;
  gap: 24px;
}

.popup {
  &__body {

    padding: 0 24px 24px;

    h3 {
      font-size: 24px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 12px;
    }

    .text {
      font-size: 16px;
      text-align: center;
    }
  }
}

@media (max-width: 1200px) {
  .count {
    font-size: 18px;

    span {
      font-size: 20px;
    }
  }
}

@media (max-width: 992px) {
  .count {
    font-size: 14px;

    span {
      font-size: 16px;
    }
  }
  .btns {
    flex-direction: column;
  }
}

@media (max-width: 540px) {
  .card {
    padding: 24px !important;

    &__title {
      font-size: 14px;
      margin-bottom: 12px;
    }

    .totals {
      flex-direction: column;
      padding: 0;
      margin: 24px 0;

    }

    .total {
      justify-content: space-between;
    }
  }

  .popup {
    &__body {

      padding: 0 12px 12px;

      h3 {
        font-size: 18px;
      }

      .text {
        font-size: 14px;
      }
    }
  }

}
</style>
