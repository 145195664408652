import Vue from "vue";
import Vuex from "vuex";
import router from "@/router";
import axios from "axios";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    isLoading: false,
    isMobile: false,
    isTablet: false,
    isDesktop: true,
    isAuth: localStorage.getItem("auth") === "true",
    profileTab: 0,
    URL: 'https://p.uni-kid.ru',
    // URL: "https://unikid-api.itlabs.top",
    token: localStorage.getItem("token") ? localStorage.getItem("token") : null,
    tokenExp: localStorage.getItem("tokenExp")
      ? localStorage.getItem("tokenExp")
      : 0,
    student: null,
    alert: {
      show: false,
      type: "info",
      msg: "",
    },
    lessonData: null,
    showLoginPopup: false,
    idempotenceKey: null,
  },
  getters: {
    isLoading: (state) => state.isLoading,
    isMobile: (state) => state.isMobile,
    isTablet: (state) => state.isTablet,
    isDesktop: (state) => state.isDesktop,
    isAuth: (state) => state.isAuth,
    profileTab: (state) => state.profileTab,
    API_URL: (state) => state.API_URL,
    URL: (state) => state.URL,
    token: (state) => state.token,
    student: (state) => state.student,
    alert: (state) => state.alert,
    lessonData: (state) => state.lessonData,
    showLoginPopup: (state) => state.showLoginPopup,
    idempotenceKey: (state) => state.idempotenceKey,
  },
  mutations: {
    TOGGLE_LOADING(state, boolean) {
      state.isLoading = boolean;
    },
    TOGGLE_MOBILE(state, boolean) {
      state.isMobile = boolean;
    },
    TOGGLE_TABLET(state, boolean) {
      state.isTablet = boolean;
    },
    TOGGLE_DESKTOP(state, boolean) {
      state.isDesktop = boolean;
    },
    TOGGLE_AUTH(state, boolean) {
      localStorage.setItem("auth", boolean);
      state.isAuth = boolean;
      if (!boolean) {
        router.push({ name: "landing" }).then((r) => r);
        state.tokenExp = 0;
        localStorage.clear();
      } else {
        router.push({ name: "lessons" }).then((r) => r);
      }
    },
    SET_PROFILE_TAB(state, number) {
      state.profileTab = number;
    },
    FETCH_TOKEN(state, token) {
      localStorage.setItem("token", token);
      state.token = token;
    },
    FETCH_STUDENT(state, student) {
      state.student = student;
    },
    showAlert(state, alert) {
      state.alert = alert;
      setTimeout(() => {
        state.alert.show ? (state.alert.show = false) : "";
      }, 8000);
    },
    getTokenExp(state, { exp }) {
      state.tokenExp = exp * 1000;
      localStorage.setItem("tokenExp", String(exp * 1000));
    },
    createLessonData(state, data) {
      state.lessonData = data;
    },
    SHOW_LOGIN_POPUP(state, boolean) {
      state.showLoginPopup = boolean;
    },
    CHANGE_IDEMPOTENCE_KEY(state, key) {
      state.idempotenceKey = key;
    },
    createUserInfo(state, data) {
      state.userInfo = data;
    },
  },
  actions: {
    setIsLoading({ state, commit }, payload) {
      commit("TOGGLE_LOADING", payload);
    },
    setIsMobile({ state, commit }, payload) {
      commit("TOGGLE_MOBILE", payload.boolean);
      if (payload.boolean) {
        commit("TOGGLE_TABLET", false);
        commit("TOGGLE_DESKTOP", false);
      }
    },
    setIsTablet({ state, commit }, payload) {
      commit("TOGGLE_TABLET", payload.boolean);
      if (payload.boolean) {
        commit("TOGGLE_MOBILE", false);
        commit("TOGGLE_DESKTOP", false);
      }
    },
    setIsDesktop({ state, commit }, payload) {
      commit("TOGGLE_DESKTOP", payload.boolean);
      if (payload.boolean) {
        commit("TOGGLE_MOBILE", false);
        commit("TOGGLE_TABLET", false);
      }
    },
    setIsAuth({ state, commit }, payload) {
      commit("TOGGLE_AUTH", payload);
    },
    setProfileTab({ state, commit }, payload) {
      commit("SET_PROFILE_TAB", payload.number);
    },
    fetchStudent({ state, commit }, payload) {
      commit("FETCH_STUDENT", payload.student);
    },
    setShowLoginPopup({ state, commit }, payload) {
      commit("SHOW_LOGIN_POPUP", payload.boolean);
    },
    changeIdempotenceKey({ state, commit }, payload) {
      commit("CHANGE_IDEMPOTENCE_KEY", payload.key);
    },
    decodeJwt({ state, commit, dispatch }, token) {
      // расшивроквка токена, запись даты окончания в локалтсорейдж и переменную
      let base64Url = token.split(".")[1];
      let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      let jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      commit("getTokenExp", JSON.parse(jsonPayload));
      commit("FETCH_TOKEN", token);
      dispatch("setIsAuth", true);
    },
    logout({ state, commit, dispatch }) {
      dispatch("setIsAuth", false);
    },
    async fetchUserInfo({ state, commit }) {
      try {
        const response = await axios({
          baseURL: state.URL + "/api/u/info",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${state.token}`,
          },
          method: "GET",
        });
        commit("FETCH_STUDENT", response.data);
      } catch (e) {
        commit("showAlert", {
          show: true,
          type: "error",
          msg: e.response.data.message,
        });
      }
    },
  },
  modules: {},
});

export default store;
export const useStore = () => store;
