<template>
  <div class="page">
    <div v-if="isDesktop" class="page__bg">
      <v-img width="100%" height="100%" src="@/assets/images/teachers_bg.png"/>
    </div>
    <v-container>
      <h1 class="title">Наши преподаватели</h1>
      <div class="teachers">
        <v-row>
          <v-col
            cols="12"
            lg="6"
            xl="4"
            v-for="teacher in teachers"
            :key="teacher.id"
          >
            <div
              @click="openTeacherPopup(teacher)"
              class="teacher"
            >
              <div class="teacher__image">
                <img :src="URL+teacher.photo"/>
              </div>
              <div class="teacher__content">
                <div class="teacher__name">{{ teacher.fullName }}</div>
                <div class="teacher__info">
                  <div><span>Обучаю:</span>{{ teacher.experience }}</div>
                  <div><span>Специализация:</span>{{ getString(teacher.specialization) }}</div>
                </div>
                <div class="teacher__status" v-if="teacher.premium">
                  <v-img src="@/assets/images/premium_status.png"/>
                </div>
                <div class="teacher__status" v-if="teacher.native">
                  SpeakingClub
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <v-dialog
      v-model="showTeacherPopup"
      max-width="1200"
      content-class="dialog-teacher"
    >
      <popup-teacher
        :teacher="activeTeacher"
        @close="showTeacherPopup = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import PopupTeacher from "@/components/popups/PopupTeacher";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Teachers",
  components: {PopupTeacher},
  data() {
    return {
      teachers: [],
      showTeacherPopup: false,
      activeTeacher: {},
    }
  },
  methods: {
    openTeacherPopup(item) {
      this.showTeacherPopup = true;
      this.activeTeacher = item;
    },
    async getTeachers() {
      this.setIsLoading(true)
      try {
        const response = await fetch(this.URL + '/api/teacher_users');
        const data = await response.json()
        this.teachers = data;
        this.activeTeacher = this.teachers[0]
      } catch (e) {
        console.log(e)
      } finally {
        this.setIsLoading(false)
      }
    },
    getString(item) {
      let string = ''
      for (let itemElement in item) {
        string += `${item[itemElement]} `
      }
      return string
    },
    ...mapActions(['setIsLoading'])
  },
  computed: {
    ...mapGetters(['isDesktop', 'URL'])
  },
  beforeMount() {
    this.getTeachers()
  }
}
</script>

<style lang="scss" scoped>
.page {
  position: relative;

  &__bg {
    width: 768px;
    height: 776px;
    position: absolute;
    bottom: 10px;
    left: -200px;
  }
}

.teachers {
  margin-bottom: 60px;

  .col-12 {
    display: flex;
  }
}

.teacher {
  position: relative;
  display: flex;
  gap: 20px;
  align-items: center;
  background: #fff;
  box-shadow: 0 4px 20px rgba(72, 34, 90, 0.15);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s;
  flex: 1 1 auto;

  &__content {
    padding: 30px 10px;
    display: flex;
    flex-direction: column;
  }

  &__image {
    width: 163px;
    height: 229px;
    flex: 0 0 163px;
    border-radius: 8px;
    overflow: hidden;
    transition: all 0.3s;
    position: relative;
    padding-top: 45%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__name {
    font-weight: 900;
    font-size: 24px;
    font-family: 'Nunito', sans-serif;
    margin-bottom: 24px;
    transition: all 0.3s;
  }

  &__info {
    margin-bottom: 24px;

    div {
      font-size: 14px;
      color: #4C4C4C;
      transition: all 0.3s;
    }

    span {
      color: #9C9C9C;
      display: inline-block;
      width: 120px;
      margin-right: 16px;
      transition: all 0.3s;
    }
  }

  &__price {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    transition: all 0.3s;
    margin-top: auto;
  }

  &__status {
    width: 117px;
    height: 23px;
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

@media (min-width: 1200px) {
  .teacher:hover {
    background: #B87DEA;

    .teacher__image {
      transform: rotate(10deg);
    }

    .teacher__name,
    .teacher__price,
    .teacher__info div,
    .teacher__info span {
      color: #fff;
    }
  }
}

@media (max-width: 1560px) {
  .teacher {
    align-items: stretch;
    gap: 15px;

    &__image {
      width: 100%;
      flex: 0 0 47%;
      height: auto;
    }

    &__name {
      font-size: 20px;
      margin-bottom: 8px;
    }

    &__content {
      padding: 16px 10px;

    }

    &__status {
      width: 80px;
      height: 17px;
    }

    &__info {
      span {
        display: block;
      }
    }

  }

}

@media (max-width: 1200px) {
  .teacher {
    &__image {
      flex: 0 0 47%;
    }

    &__name {
      font-size: 24px;
    }

    &__content {
      padding: 32px 10px;
    }
  }

}

@media (max-width: 992px) {
  .teacher {
    &__image {
      flex: 0 0 30%;
    }
  }
}

@media (max-width: 768px) {

  .teacher {
    gap: 0;

    &__image {
      flex: 0 0 37%;
    }

    &__name {
      font-size: 18px;
    }

    &__content {
      padding: 24px 10px;
    }

    &__info {
      margin-bottom: 12px;

      div {
        font-size: 12px;
      }

      span {
        width: 100px;
        display: inline-block;
        font-size: 12px;
        margin-right: 16px;
      }
    }

  }
}
</style>
